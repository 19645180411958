import Logotipo from '../assets/logo branca.png';
import styled from 'styled-components';
import BotaoCallToAction from './BotaoCallToAction';
import DigitalyLogo from '../assets/logo-digitaly.png';
import { Link } from 'react-router-dom';
import { devices } from '../helpers/devices';
import { ContatoItem, MobileContatoItem } from './ContatoItem';
import Modal from 'react-modal';
import { useState } from 'react';

const StyledFooter = styled.footer`
    background: ${props => props.theme.bgColor3};
    display: flex;
    flex-direction: column;
    `;

const FooterTop = styled.div`
    margin: 3rem 6rem;
    display: grid;
    grid-gap: .4rem;
    grid-template-areas: 
    "contato contato contato botoes"
    "contato contato contato botoes"
    "links   links   links   botoes";

    @media ${devices.laptop} {
        grid-template-areas:
        "contato contato contato"
        "links   links   links"
        "botoes  botoes  botoes"
        "tel     tel     tel";
    }

    @media ${devices.tablet} {
        margin: 3rem 2rem;
    }
    `;

const ContatoSection = styled.div`
    color: ${props => props.theme.white1};
    grid-area: contato;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &:last-child {
        display: none;
    }
    `;

const Img = styled.img`
    position: relative;
    width: 280px;
    height: auto;
    `;

const BotoesSection = styled.div`
    padding-left: 1rem;
    border-left: 1px solid ${props => props.theme.gray3};
    grid-area: botoes;
    display: flex;
    flex-direction: column;

    & p {
        color: ${props => props.theme.white1};
        text-align: center;
        font-weight: bold;
        margin: .6rem 0;
    }

    & div {
        width: 100%;
        padding: 0 1rem;
    }

    @media ${devices.laptop} {
        margin-top: 1.5rem;
        padding: 0;
        border-left: none;
        flex-direction: row;
        justify-content: space-around;
    }

    @media ${devices.mobileL} {
        flex-direction: column;
    }
    `;

const LinksSection = styled.div`
    margin: 0 0 0 1rem;
    border-top: 1px solid ${props => props.theme.gray3};
    grid-area: links;
    display: flex;
    justify-content: space-around;
    align-items: center;

    & a {
        color: ${props => props.theme.white1};
    }

    @media ${devices.laptop} {
        margin: 0;
        margin-top: 1.5rem;
        border-top: none;
    }

    @media ${devices.mobileL} {
        text-align: center;
    }
    `;

const FooterBottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: ${props => props.theme.gray1};
    color: ${props => props.theme.white1};
    `;

const Flex = styled.span`
    display: flex;
    align-items: center;

    & h4 {
        margin-right: 5px;
    }
    `;

    const A = styled.a`
    font-size: 0.9rem;
    color: ${props => props.theme.white2};
    cursor: pointer;
    @media ${devices.tablet} {
        height: 100%;
        color: ${props => props.theme.white1};
        padding: 0.5rem;
        cursor: pointer;
        font-size: 1.2rem;
    }
    `;

    const Button = styled.button`
    background-color: white;
    color: red;
    font-size: 12px;
    cursor: pointer;
    background: white;
    border: 0;
    outline: 0;
  `;

  const ButtonCustom = styled.button<{ fullWidth: boolean }>`
    display: inline;
    padding: 1rem 2rem;
    width: ${props => props.fullWidth ? "100%" : "max-content"};
    background-color: ${props => props.theme.callToActionButtonColor};
    color: ${props => props.theme.grey2};
    font-weight: bold;
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;

    &:hover {
        background: ${props => props.theme.callToActionButtonHoverColor};
        transition: 400ms;
    }
    `;
  
  const customStyles = {      
        position: "absolute",
        backgroundColor: "#FFF",
        padding: "15px",
        zIndex: "1000",
        width: "35%",
        borderRadius: ".5em",
      overlay: {zIndex: 1000}
    }

const Footer = (): JSX.Element => {
    const currentYear: number = new Date().getFullYear();
        const [modalIsOpen, setIsOpen] = useState<boolean>(false);

    function openModal() {
        setIsOpen(true);
      }
    
      function closeModal() {
        setIsOpen(false);
      }
    return (
        <>
        <StyledFooter>
            <FooterTop>
                <ContatoSection>
                    <div>
                        <Link to="/#topo"><Img height="128px" width="128px" src={Logotipo} alt="Logotipo da Dascam"/></Link>
                    </div>
                    <ContatoItem />
                </ContatoSection>
                <BotoesSection>
                    <div>
                        <p>Seja um colaborador</p>
                        <BotaoCallToAction
                            LinkOptions={
                                {
                                    attachLink: "/formulário",
                                    isRouter: true,
                                }
                            }
                            fullWidth={true}
                            >
                                Enviar Curriculo
                        </BotaoCallToAction>
                    </div>
                    <div>
                        <p>Está com problemas?</p>
                        <ButtonCustom
                            onClick={openModal}
                            fullWidth={true}
                            >
                                Ouvidoria
                        </ButtonCustom>
                    </div>
                </BotoesSection>
                <LinksSection>
                <table width={'100%'}>
                    <tr>
                    <td><A target="_blank" href={"https://dascam.s3.amazonaws.com/CEC_CO%CC%81DIGO_DE_E%CC%81TICA_E_CONDUTA.pdf"} rel="noreferrer">Código de Ética e de Conduta</A></td>
                    <td><Link to="/ouvidoria#relatorios_de_ouvidoria">Relatórios de Ouvidoria</Link></td> 
                    <td><A target="_blank" href={"https://dascam.s3.amazonaws.com/RSAC+e+Plano+de+Ac%CC%A7a%CC%83o+de+Efetividade.pdf"} rel="noreferrer">Politica de RSAC e Plano de Ação de Efetividade</A></td> 
                    </tr>
                    <tr>
                        <td><A target="_blank" href={"https://dascam.s3.amazonaws.com/seguranca_cibernetica.pdf"} rel="noreferrer">Política de Segurança Cibernética</A></td>
                        <td><A target="_blank" href={"https://dascam.s3.amazonaws.com/REGULAMENTO_CANAL+DE+DENUNCIAS.pdf"} rel="noreferrer">Regulamento Canal de Denúncias</A></td>
                        <td><A target="_blank" href={"https://dascam.s3.amazonaws.com/Politica-LGPD.pdf"} rel="noreferrer">Política LGPD e Nomeação do DPO</A></td>
                        {/*<td><A target="_blank" onClick={openModal} rel="noreferrer">Ouvidoria</A></td>    */  }                
                    </tr>
                    <tr>
                    <td><Link to="/serviços#demonstrativos_financeiros">Demonstrativos Financeiros</Link></td>                    
                    </tr>
                </table>
                </LinksSection>
                
                <MobileContatoItem />
            </FooterTop>
            <FooterBottom>
                <Flex>
                    <h4>&copy; {currentYear} | Dascam - Corretora de Câmbio Ltda | Desenvolvido por </h4>
                    <A href="https://digitaly.tech/" target="_blank" rel="noreferrer noopener">
                        <img width="160px" height="40px" src={DigitalyLogo} alt="Logotipo da Digitaly"/>
                    </A>
                </Flex>
            </FooterBottom>
        </StyledFooter>
                <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div><b style={{ color:'#aa6443'}}>OUVIDORIA</b></div>
                    <div><Button onClick={closeModal}>Fechar</Button></div>
                    
                </div>
                <div>
                <br/>Esta instituição integra o <strong style={{ color:'#aa6443'}}>SISTEMA DE COMPARTILHAMENTO DE OUVIDORIA da Associação Brasileira de Câmbio - ABRACAM.</strong>
                <br/><br/>
                <strong style={{ color:'#aa6443'}}>QUANDO UTILIZAR O CANAL DE OUVIDORIA?</strong><br/><br/>
                O serviço de <strong style={{ color:'#aa6443'}}>OUVIDORIA</strong> deve ser <strong style={{ color:'#aa6443'}}>acionado apenas quando os canais regulares de atendimento da DASCAM CORRETORA DE CÂMBIO LTDA pelo telefone que consta neste Site</strong> não atenderem, ou atenderem insatisfatoriamente suas necessidades, por qualquer motivo.<br/><br/>
                Os contatos com a Ouvidoria são formalizados internamente, e possuem prazos para atendimento e solução. Portanto, para que a sua demanda possa ser registrada, analisada e respondida, <strong style={{ color:'#aa6443'}}>é obrigatório informar com clareza no mínimo os seguintes dados</strong>:<br/><br/>
                1) Nome(s) da(s) pessoa(s) com quem se comunicou antes<br/><br/>
                2) Sua identificação e contato:<br/>
                &nbsp;&nbsp;&nbsp;-Nome completo<br/>
                &nbsp;&nbsp;&nbsp;-CPF/CNPJ ou Passaporte<br/>
                &nbsp;&nbsp;&nbsp;-E-mail, ou Endereço de Correspondência (com CEP), para que você possa receber o retorno da OUVIDORIA.<br/><br/>
               3) O motivo de seu contato.<br/><br/>
               <strong style={{ color:'#aa6443'}}>Como entrar em contato com a Ouvidoria?</strong><br/><br/>
               Ligar para <strong style={{ color: 'red' }}>0800 770-5422</strong> (somente chamadas realizadas no Brasil)<br/>
               Este serviço funciona de forma automática, fale com clareza as informações solicitadas.<br/>
               Disponível das 9:00 às 16:30 horas, de segunda a sexta-feira, exceto feriados.<br/><br/>
               Ou através deste <strong><a style={{ color: 'red' }} href="https://risc.advicetech.com.br/Ouvidoria_front/paginas/default.aspx?CD_CHAVE=6485A558-0E33-4E12-923C-DFC8FC713D60" target="_blank" rel="noreferrer">FORMULÁRIO</a></strong>.
        
                </div>
              </Modal>
        </>
    )
}

export default Footer;