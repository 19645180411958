import Header from '../components/Header';
import MainSection from '../components/MainSection';
import ServicosCompletoSection from '../components/ServicosCompletoSection';
import Footer from '../components/Footer';

const Servicos = (): JSX.Element => (
    <>
        <Header />
        <MainSection fadeDown={true}height={120}/>
        <ServicosCompletoSection />
        <Footer />
    </>
);

export default Servicos;