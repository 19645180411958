import { useState } from 'react';
import styled from 'styled-components';
import { devices } from '../helpers/devices';
import useScrollTo from '../helpers/useScrollTo';

const Section = styled.section`
    margin: 0 8rem 2rem 8rem;

    & article {
        margin-bottom: 3rem;
    }

    & h2 {
        color: ${props => props.theme.headerTextColor1};
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    & a {
        display: block;
        width: max-content;
        margin-bottom: .5rem;
    }

    & p {
        margin-top: 1rem;
        color: ${props => props.theme.gray2}
    }

    @media ${devices.laptop} {
        margin: 0 2rem 1rem 2rem;
    }
    `;


const OuvidoriaCompletoSection = (): JSX.Element => {
    const [ouvidoria] = useState([
        {
            description: "Relatório de 30 de junho de 2023",
            link: "https://dascam.s3.amazonaws.com/RELATO%CC%81RIO+DO+DIRETOR+RESP+P+OUVIDORIA+30.06.2023.pdf",
        },
        {
            description: "Relatório de 31 de dezembro de 2022",
            link: "https://dascam.s3.amazonaws.com/RELATO%CC%81RIO+DO+DIRETOR+RESP+P+OUVIDORIA+31.12.22.pdf",
        },
        {
            description: "Relatório de 30 de junho de 2022",
            link: "https://dascam.s3.amazonaws.com/RELATO%CC%81RIO_OUVIDORIA.pdf",
        }
    ]);
    const relatoriosDeOuvidoriaRef = useScrollTo<HTMLHeadingElement>("#relatorios_de_ouvidoria", { behavior: "smooth", block: "start"});
    
    return (
        <Section>
            <article>
                <h2 ref={relatoriosDeOuvidoriaRef}>Relatórios de Ouvidoria</h2>
                {ouvidoria.map(item => (
                    <a key={item.link} href={item.link} target="_blank" rel="noreferrer noopener">{item.description}</a>
                ))}
            </article>
        </Section>
    )
};

export default OuvidoriaCompletoSection;