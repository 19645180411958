import { useEffect, useState } from "react";
import styled from "styled-components";
import { watsonRafa } from "../../helpers/VariableAmbient";

const Iframe = styled.iframe<{
  iframeHeight: string;
  iframeWidth: string;
}>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  border: none;
  border-radius: 10px;
  z-index: 500;
  height: ${props => props.iframeHeight};
  width: ${props => props.iframeWidth};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.1rem;
`;

interface IframeData {
  iframeState: "OPEN" | "CLOSED" | "EXPANDED";
  channel: string;
}

const WatsonIframe = (): JSX.Element => {
  const [chatHeight, setChatHeight] = useState<string>("100px");
  const [chatWidth, setChatWidth] = useState<string>("100px");
  let width = window.screen.availWidth;

  useEffect(() => {
    const receiveData = (event: MessageEvent<any>) => {
      const { data } = event;

      try {
        const decodedData: IframeData = JSON.parse(data);

        if (decodedData.channel === "watson") {
          switch (decodedData.iframeState) {
            case "CLOSED":
              setChatHeight("100px");
              setChatWidth("100px");
              break;
            case "OPEN":
              if (width >= 1800) {
                setChatHeight("60%");
                setChatWidth("25%");
              } else if (width >= 1200) {
                setChatHeight("70%");
                setChatWidth("35%");
              } else if (width >= 900) {
                setChatHeight("70%");
                setChatWidth("45%");
              } else if (width >= 600) {
                setChatHeight("70%");
                setChatWidth("65%");
              } else if (width <= 599) {
                setChatHeight("95%");
                setChatWidth("95%");
                // } else {
                //   setChatHeight("70%");
                //   setChatWidth("95%");
              }
              break;
            case "EXPANDED":
              setChatHeight("98%");
              setChatWidth("98%");
              break;
            default:
              setChatHeight("100px");
              setChatWidth("100px");
              break;
          }
        }
      } catch (error) {}
    };

    window.addEventListener("message", receiveData);
    return () => {
      window.removeEventListener("message", receiveData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <Iframe
      src={watsonRafa}
      id="watsonIa"
      width="100"
      height="100"
      title="Chat Rafa"
      iframeWidth={chatWidth}
      iframeHeight={chatHeight}
    />
  );
};

export default WatsonIframe;
