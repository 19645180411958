import { useState } from 'react';
import styled from 'styled-components';
import { devices } from '../helpers/devices';
import Servico from '../interfaces/Servico';
import ServicoComponent from './ServicoComponent';
import useScrollTo from '../helpers/useScrollTo';

const Section = styled.section`
    margin: 3rem 0;
    `;

const H2 = styled.h2`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.headerTextColor1};
    font-size: 1.875rem;
    font-weight: bold;
    `;

const ServicosContainer = styled.div`
    margin: 2rem 8rem 8rem 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${devices.laptop} {
        margin: 2rem 2rem;
    }
    `;

const ServicosSection = (): JSX.Element => {
    const [servicos, setServicos] = useState<Servico[]>([
        {
            title: "Intermediação de operações de Câmbio",
            subItem: [
                {
                    title: "Mesa de operações",
                    dropdownContent: [
                        "Trader: Sob a coordenação de dois sócios a Dascam oferece a seus clientes uma mesa operacional composta por “Traders” experientes que tem como único objetivo buscar as melhores condições para a realização de sua operação.",
                    ],
                },
                {
                    title: "Backoffice",
                    dropdownContent: [
                        "Análise: Apreciação da documentação suporte das operações a serem realizadas, sob estrita observância das normas cambiais vigentes;",
                        "Controle: Nosso sistema está preparado para acompanhar o saldo e vinculação dos documentos suporte das operações de câmbio (DI, DUIMP, SD e DU-E, Fatura) aos respectivos contratos de câmbio;",
                        "Avisos: Envio de e-mail a fornecedores, no dia da liquidação dos contratos de câmbio, informando os detalhes das transferências (valor, dados do canal bancário e os números das faturas\\documentos);",
                        "Incidência de Tributos: Análise dos tributos incidente sobre as remessas financeiras ao exterior, elaboração de uma matriz de tributos;",
                        "Guias de Recolhimento de Tributos: Preenchimento de DARFs dos tributos federais incidentes nas operações de remessas financeiras;"
                    ],
                },
            ],
        },
        {
            title: "Orientação técnica",
            subItem: [
                {
                    title: "Retorno de capital",
                    dropdownContent: [
                        "O retorno do capital estrangeiro investido no país pode ser realizado a qualquer momento, no entanto, se trata de uma operação que demanda de observação na elaboração dos documentos necessários, tributação aplicável etc. Nosso time está preparado a fornecer as informações necessárias para que você possa realizar essa operação com segurança;",
                    ],
                },
                {
                    title: "Remessa de lucros ou dividendos",
                    dropdownContent: [
                        "Os lucros ou dividendos distribuídos para não residentes não estão sujeitos ao imposto de renda independentemente da residência ou domicílio do beneficiário, entretanto, a realização da remessa desses recursos ao exterior demanda de certa avaliação.",
                    ],
                },
                {
                    title: "Juros sobre Capital Próprio",
                    dropdownContent: [
                        "Os Juros sobre o Capital Próprio pagos para não residentes são tributados às alíquotas de 15% ou 25%. Caso o beneficiário tenha residência em Paraíso Fiscal, a tributação já é um tema pacificado no mercado. Desde o cálculo do JCP até sua tributação e remessa ao exterior existem várias etapas que merecem um olhar multidisciplinar. Nossa equipe de assessoria está preparada para essa avaliação;",
                    ],
                },
                {
                    title: "Conversão de dívidas em investimentos",
                    dropdownContent: [
                        "Qualquer dívida que possa ser remetida ao exterior poderá ser convertida em investimento externo direto. A operacionalização geralmente ocorre por meio da realização da operações símbolicas de câmbio, e à partir de 1º de novembro de 2023 não será mais necessária a realização das operações simultâneas de câmbio. O procedimento requer a elaboração de documentos específicos para suportar essa movimentação. Temos profissionais prontos para orientá-los adequadamente;",
                    ],
                },
                {
                    title: "Empréstimos Externos",
                    dropdownContent: [
                        "Quando da operação de ingresso de recursos provenientes de empréstimo externo contratado de forma direta ou mediante emissão de títulos no mercado internacional, a primeira providência é efetuar o registro das condições da operação no Banco Central do Brasil mediante a efetivação de um RDE-ROF. A efetivação adequada desse registro é essencial para o recebimento dos valores e para as movimentações posteriores. Nossa equipe está preparada para efetivar esse registro;",
                    ],
                },
                {
                    title: "Investimentos no Exterior",
                    dropdownContent: [
                        "A remessa ao exterior a título de Capital Brasileiro no Exterior está devidamente normatizada pelo Banco Central do Brasil e gera para a empresas e pessoas naturais, residentes ou sediadas no Brasil, obrigações acessórias. Nosso time de assessoria estará à disposição para esclarecer todas as questões relacionadas;",
                    ],
                },
                {
                    title: "Tributos",
                    dropdownContent: [
                        "Avaliação do aspecto fiscal das operações de câmbio e capitais internacionais. Atualmente, uma remessa ao exterior pode sofrer a incidência de até 6 tributos. Somente um time altamente especializado pode fornecer a orientação adequada para uma questão tão complexa e significativa para essas operações;",
                    ],
                },
            ],
        },
        {
            title: "Registros Eletrônicos",
            subItem: [
                {
                    title: "Registros Declaratórios Eletrônicos (RDE-IED, RDE-ROF)",
                    dropdownContent: [
                        "No módulo Registro Declaratório Eletrônico são registradas as operações de investimentos externos diretos (IED), empréstimo externo contratado de forma direta ou mediante emissão de títulos no mercado internacional, financiamentos com prazo superior a 360 dias, transferência de tecnologia (ROF), dentre outros, devendo ser observada a obrigação normativa de se manter os registros atualizados;",
                    ],
                },
                {
                    title: "Censo da Capitais Estrangeiros no País (Censo)",
                    dropdownContent: [
                        "O Censo de Capitais Estrangeiros no País tem o objetivo de compilar estatísticas do setor externo, em especial, a posição de investimento internacional, subsidiando a formulação da política econômica e auxiliando atividades de pesquisadores econômicos e de organismos internacionais. O Censo é prestado anualmente pelas pessoas jurídicas sediadas no País, com participação direta de não residentes em seu capital social, em qualquer montante e com patrimônio líquido igual ou superior ao equivalente a US$100 milhões (cem milhões de dólares dos Estados Unidos da América), em 31 de dezembro do ano-base ou com saldo devedor total de créditos comerciais de curto prazo (exigíveis em até 360 dias) concedidos por não-residentes em valor igual ou superior ao equivalente a US$10 milhões (dez milhões de dólares dos Estados Unidos da América). E quinquenalmente para as empresas sediadas no país com participação direta de não residente em seu capital social, em qualquer montante, na data base ou saldo devedor total de créditos comerciais de curto prazo (exigíveis em até 360 dias) – devidos à não residentes, em valor igual ou superior a US$ 1 milhão (Um milhão de dólares dos Estados Unidos da América), na data base."
                    ],
                },
                {
                    title: "Declaração de Capitais Brasileiros no Exterior (CBE)",
                    dropdownContent: [
                        "Declaração de Capitais Brasileiros no Exterior (CBE), é uma pesquisa conduzida pelo Banco Central do Brasil (BCB), tem por objetivo coletar dados que compõem o ativo externo da economia brasileira. A CBE deve ser prestada anualmente pelas pessoas físicas ou jurídicas residentes, domiciliadas ou com sede no Brasil que sejam detentoras de ativos de quaisquer naturezas em valor igual ou superior a US$. 1 milhão em 31 de dezembro de cada ano ou trimestralmente para as pessoas físicas ou jurídicas residentes, domiciliadas ou com sede no Brasil que nas datas-bases de 31 de março, 30 de junho e 30 de setembro de cada ano-base sejam detentoras de ativos de quaisquer naturezas (depósitos, bens e direitos, móveis, imóveis, etc.) em valor igual ou superior a US$ 100 milhões.",
                    ],
                },
                {
                    title: "Declaração Econômico-financeira (DEF)",
                    dropdownContent: [
                        "A DEF é uma obrigação instituída por meio da Circular nº 3.814/16 com objetivo de coletar informações econômico-financeiras das empresas sediadas no país que tenham em seu quadro de investidores pessoas físicas ou jurídicas residentes, domiciliadas ou sediadas no exterior. As informações requeridas na DEF, serão prestadas anualmente por todas as empresas que tenham em seu quadro de investidores pessoas físicas ou jurídicas residentes, domiciliadas ou sediadas no exterior e trimestralmente por empresas que tenham na composição de seu quadro societário investidores pessoas físicas ou jurídicas residentes, domiciliadas ou sediadas no exterior e ativos e/ou patrimônio líquido igual ou superior a R$ 250 milhões.",
                    ],
                },
            ],
        },
        {
            title: "Terceirização de atividades relacionadas a operações de câmbio",
            subItem: [
                {
                    title: "Backoffice",
                    dropdownContent: [
                        "Todos os serviços relacionados no tópico Backoffice;",
                    ],
                },
                {
                    title: "Atendimento",
                    dropdownContent: [
                        "Contato direto com os colaboradores das várias áreas solicitantes de pagamentos e/ou recebimentos do exterior;",
                    ],
                },
                {
                    title: "Sistemas",
                    dropdownContent: [
                        "Nós dispomos de profissionais com conhecimento em vários sistemas de câmbio e comércio exterior que possibilitam uma atuação nas fases “pré” e “pós” a realização das operações de câmbio, inclusive dos lançamentos contábeis e conciliação bancária;",
                    ],
                },
                {
                    title: "Automação",
                    dropdownContent: [
                        "Utilizando de softwares de última geração temos condições de proporcionar a nossos clientes uma revisão de processos com objetivo de automatizar várias tarefas",
                    ],
                },
                {
                    title: "Demais tarefas",
                    dropdownContent: [
                        "Outras atividades administrativas tais como levantamento de métricas, arquivamento de documentos físicos ou digitalmente etc.",
                    ],
                },
            ],
        },
        {
            title: "Assessoria",
            subItem: [
                {
                    title: "Assistência Técnica",
                    dropdownContent: [
                        "Necessária ao cumprimento de normas e regulamentos do Banco Central bem como qualquer outro órgão no que tange às operações de câmbio e capitais internacionais;",
                    ],
                },
                {
                    title: "Inteligência Artificial",
                    dropdownContent: [
                        "A Dascam disponibiliza a seus clientes a Rafa, uma inteligência artificial, pronta para auxiliar em várias dúvidas sobre câmbio, capitais estrangeiros no Brasil e capitais brasileiros no exterior;",
                    ],
                },
                {
                    title: "Informes",
                    dropdownContent: [
                        "Envio de informes técnicos relacionados à legislação cambial, capitais internacionais, obrigações acessórias etc.;",
                    ],
                },
                {
                    title: "Índices",
                    dropdownContent: [
                        "Boletins de Índices (diário);",
                    ],
                },
            ],
        },
    ]);
    const h2Ref = useScrollTo<HTMLHeadingElement>("#serviços", { behavior: "smooth", block: "start" });

    return (
        <Section>
            <H2 ref={h2Ref}>Serviços</H2>
            <ServicosContainer>
                {servicos.map((item, index) => (
                    <ServicoComponent key={index} servico={item}/>
                ))}
            </ServicosContainer>
        </Section>
    )
}

export default ServicosSection;
