import Landing from './pages/Landing';
import Servicos from './pages/Servicos';
import Formulario from './pages/Formulario';
import Erro404 from './pages/Erro404';
import { 
    BrowserRouter as Router,
    Switch, Route
} from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Sobre from './pages/Sobre';
import WatsonIframe from './components/Iframe/WatsonIframe';
import { watsonShow } from './helpers/VariableAmbient';
import Ouvidoria from './pages/Ouvidoria';

const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        scroll-behavior: smooth;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    `;

const theme = {
    bgColor1: "#06477f",
    bgColor2: "#dce3ed",
    bgColor3: "#00051e",
    headerTextColor1: "#aa6443",
    headerTextColorAlt1: "#7f3422",
    callToActionButtonColor: "#ffb121",
    callToActionButtonHoverColor: "#ffde49",
    linkColor1: "#41b8ff",
    confirmColor: "#14ad33",
    denyColor: "#bb1818",
    white1: "#FFF",
    white2: "#f2f2f2",
    white3: "#ddd",
    gray1: "#111",
    gray2: "#242424",
    gray3: "#b4b9bd",
    gray4: "#121212",
    transparent_opaque: "rgba(24, 24, 24, 0.8)",
    transparent_clear: "rgba(24, 24, 24, 0.5)",
}

const App = (): JSX.Element => {
    return (
        <>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
            { watsonShow ? <WatsonIframe />: <></>}
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Landing />
                        </Route>
                        <Route path="/serviços">
                            <Servicos />
                        </Route>
                        <Route path="/ouvidoria">
                            <Ouvidoria />
                        </Route>
                        <Route path="/formulário">
                            <Formulario />
                        </Route>
                        <Route path="/sobre">
                            <Sobre />
                        </Route>
                        <Route path="*">
                            <Erro404 />
                        </Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </>
    );
};

export default App;