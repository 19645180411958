import { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';
import BACENLogo from '../assets/SeloAbracam.png';
import { devices } from '../helpers/devices';
import Card from '../interfaces/Card';
import BotaoCallToAction from './BotaoCallToAction';
import CardComponent from './CardComponent';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

const CertificadoContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 1fr));
    margin: 6rem 8rem 6rem 8rem;

    @media ${devices.laptopL} {
        margin: 6rem 4rem 6rem 4rem;   
    }

    @media ${devices.laptop} {
        margin: 8rem 2rem 1rem 2rem;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(2, minmax(10px, 1fr));
        justify-content: center;
    }
    `;

const Certificado = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 3rem;
    grid-column-start: 1;
    color: ${props => props.theme.headerTextColor1};

    & h2 {
        margin-bottom: 2rem;
        font-size: 1.875rem;
    }

    & p {
        color: ${props => props.theme.gray2};
        font-size: 1.375rem;
        margin-bottom: 1rem;
    }

    @media ${devices.laptop} {
        grid-row-start: 1;
        padding-right: 0;
    }

    @media ${devices.mobileL} {
        grid-column-end: 3;
    }
    `;

const Depoimentos = styled.div`
    padding-left: 80px;
    padding-right: 128px;
    grid-column-start: 2;

    @media ${devices.laptop} {
        width: 100%;
        max-width: 380px;
        padding: 0;
        grid-row-start: 2;
        grid-column-start: span 2;
        align-self: center;

        position: relative;
        top: 180px;
        left: 30%;
    }

    @media ${devices.tablet} {
        left: 23%;   
    }

    @media ${devices.mobileL} {
        padding-right: 10px;
        left: 2%;
    }
    `;

const HighlightTextBlack = styled.span`
    color: ${props => props.theme.gray2};
    `;

const Logo = styled.img`
    height: 150px;
    position: absolute;
    top: -40px;
    left: -70px;
    border-radius: 15%;
    box-shadow: 3px 2px 3px rgba(1,1,1,.4);

    @media ${devices.laptop} {
        display: none;
    }
    `;

const LogoMobile = styled(Logo)`
    position: static;
    display: none;

    @media ${devices.laptop} {
        display: block;
        grid-row-start: 1;
        grid-column-start: 2;
    }

    @media ${devices.mobileL} {
        display: none;
    }
    `;

const RelativeDiv = styled.div`
    position: relative;
    height: 100%;
    width: 100%;

    @media ${devices.laptop} {
        max-width: 380px;
    }
    `;

const CertificadoSection = () => {
    const [cards, setCards] = useState<Card[]>([
        {
            title: "Bridgestone",
            text: "Como tesoureiro de multinacionais, tenho a Dascam como a principal referencia em matéria cambial, seja em âmbito regulatório, seja na definição e operacionalização de estratégias relacionadas à matéria. Nestes mais de 20 anos de parceria, a Dascam segue demonstrando notória capacidade técnica e um compromisso permanente com o desenvolvimento dos negócios de seus clientes, sempre pautada em sólidos valores éticos.",
            author: "Roberto Prado – Tesoureiro da Bridgestone",
        },
        {
            title: "Sertrading",
            text: "A Sertrading movimenta mais de R$ 12 bilhões por ano em importações para mais de 100 clientes. Portanto, o fechamento de câmbio é atividade essencial ao nosso negócio e confiamos à Dascam as tarefas de processamento e reanálise destas operações, além de consultorias em assuntos específicos. São 20 anos de uma parceria muito produtiva, tendendo a melhorar cada vez mais em virtude das várias ferramentas tecnológicas que a corretora coloca à nossa disposição. Isto nos traz segurança e economia. Muito obrigado Dascam, keep on walking.",
            author: "Robson Reis – CFO da Sertrading",
        },
        {
            title: "Motorola",
            text: "A MOTOROLA preza a parceria com a DASCAM. Trata-se de uma empresa que tem um grupo de profissionais altamente qualificados, que através de transparência, ética e integridade, nos aconselham nas atividades envolvidas na área de câmbio. Parabéns, time DASCAM!",
            author: "Osvaldo Vital – Tesoureiro Motorola",
        },
    ]);

    const sliderSettings = {
        dots: false,
        slidesToShow: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }

    return (
        <CertificadoContainer>
            <Certificado>
                <div>
                    <h2>A Dascam é uma instituição autorizada a funcionar pelo Banco Central do Brasil na intermediação em operações de câmbio</h2>
                    <p>Nossa empresa não tem posição própria e atua exclusivamente na intermediação de operações de câmbio de nossos clientes junto aos bancos.</p>
                    <p>Somos uma empresa com 30 anos de mercado, atuando de forma transparente e alinhada com as mais recentes normas de Compliance do Banco Central do Brasil. A Dascam já obteve o Selo de Conformidade da ABRACAM. Isso atesta que adotamos as melhores práticas de governança, procedimentos, organização e compliance, refletindo na segurança das operações por nós intermediadas e em um atendimento de excelência aos nossos clientes.</p>
                </div>
                {/* <BotaoCallToAction
                    LinkOptions={
                        {
                            attachLink: "http://www.dascam.com.br/2014/certidao_bacen.pdf",
                            isRouter: false,
                            target: "_blank"
                        }
                    }
                    >
                        Ver Certificado
                </BotaoCallToAction> */}
            </Certificado>
            <LogoMobile width="128px" height="128px" src={BACENLogo} alt="Selo de conformidade ABRACAM"/>
            <Depoimentos>
                <RelativeDiv>
                    <Slider {...sliderSettings}>
                        {cards.map((card,index) => 
                            <CardComponent
                                key={index}
                                card={card}
                                cardOptions={
                                    {
                                        isItalic: true,
                                        cardColor: "#06477f",
                                        titleColor: "#FFF",
                                        textColor: "#FFF",
                                    }
                                }
                            />
                        )
                        }
                    </Slider>
                    <Logo width="128px" height="128px" src={BACENLogo} alt="Selo de conformidade ABRACAM"/>
                </RelativeDiv>
            </Depoimentos>
        </CertificadoContainer>
    )
};

export default CertificadoSection;