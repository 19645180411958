import Logotipo from '../assets/logo branca.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { devices } from '../helpers/devices';
import useScrollTo from '../helpers/useScrollTo';
import LoginBtn from '../assets/login icon.png';
import OpenMenu from './HeaderMobile/OpenMenu';
import { ReactComponent as IconMenu } from "../assets/IconMenu.svg";
import { useState } from 'react';
import Modal from 'react-modal';

const StyledHeader = styled.header`
    background: ${props => props.theme.bgColor1};
    height: auto;
    width: auto;
    `;

const Nav = styled.nav`
    margin: 0 8rem;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media ${devices.laptop} {
        margin: 0 2rem;
    }

    @media ${devices.tablet} {
        margin: 0;
        justify-content: space-between;
        align-items: flex-start;
    }
    `;

const Img = styled.img`
    position: relative;
    width: 100%;
    height: auto;
    `;

const LinkImg = styled(Link)`
    max-width: 400px;
    width: 100%;
    height: 100%;
    padding: .1rem;
`;

const Ul = styled.ul`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;

    & li {
        transform: scale(0.9);

        &:hover {
            transform: scale(1);
        }
    }

    @media ${devices.tablet} {
        display: none;
    }
    `;

const StyledLink = styled(Link)`
    font-size: 1.2rem;
    color: ${props => props.theme.white2};
    text-decoration: none;

    @media ${devices.tablet} {
        font-size: 1rem;
    }
    `;

const A = styled.a`
    font-size: 1.2rem;
    color: ${props => props.theme.white2};
    text-decoration: none;
    cursor: pointer;
    @media ${devices.tablet} {
        height: 100%;
        color: ${props => props.theme.white1};
        text-decoration: none;
        padding: 0.5rem;
        cursor: pointer;
        font-size: 1.2rem;
    }
    `;

const LoginButton = styled.img`
    width: 80px;
    height: 80px;
    cursor: pointer;

@media ${devices.laptop} {
    width: 70px;
    height: 70px;
}
`;

const ButtonMobile = styled.button`
    padding: 0.5rem;
    margin: 0.1rem;
    border: none;
    background: transparent;
    text-align: start;
    border-bottom: 0.1rem solid ${props => props.theme.callToActionButtonColor};
    width: 100%;
    color: ${props => props.theme.text2};

    & p:hover {
        text-decoration: underline;
    }
`;

const LinkOne = styled(Link)`
    height: 100%;
    color: ${props => props.theme.white1};
    text-decoration: none;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;
`;

const MenuMobile = styled.div`
    display: none;
    width: auto;
    height: 40px;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 6px;
    background: ${props => props.theme.primary_dark};

    & svg {
        width: 30px;
        height: 30px;
        fill: ${props => props.theme.white1};
    }

    @media ${devices.tablet} {
        display: flex;
    }
`;

const Button = styled.button`
  background-color: white;
  color: red;
  font-size: 12px;
  cursor: pointer;
  background: white;
  border: 0;
  outline: 0;
`;

const customStyles = {      
      position: "absolute",
      backgroundColor: "#FFF",
      padding: "15px",
      zIndex: "1000",
      width: "35%",
      borderRadius: ".5em",
    overlay: {zIndex: 1000}
  }

const Header = (): JSX.Element => {
    const headerRef = useScrollTo<HTMLElement>("#topo", { block: "start" });
    const [showUtilities, setShowUtilities] = useState<boolean>(false);
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);

    function openModal() {
        setIsOpen(true);
      }
    
      function closeModal() {
        setIsOpen(false);
      }

    return (<>
    <StyledHeader ref={headerRef}>
            <Nav>
                <LinkImg to="/"><Img src={Logotipo} alt="Logotipo da Dascam"/></LinkImg>
                <Ul>
                    <li><StyledLink to="/sobre">Quem somos?</StyledLink></li>
                    <li><StyledLink to="/#serviços">Serviços</StyledLink></li>
                    <li><A
                        onClick={openModal}
                        >
                            Ouvidoria
                        </A>
                    </li>
                    <li>
                    <A
                            href="http://www.syscambio.com.br:81/WebSac/auth/index/?token=QOXzqFLGQPmid82FTqVPwg%3D%3D&type=3"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Denúncia
                        </A>
                    </li>
                    <li><StyledLink to="/formulário">Contato</StyledLink></li>
                    {process.env.SHOW_LOGIN ? <li>
                        <a 
                            href={process.env.REACT_APP_DIRECT}                 
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <LoginButton width="128px" height="128px" src={LoginBtn} alt="Botão de login"/>
                        </a>
                    </li>:<></>}
                </Ul>
                <MenuMobile onClick={() => setShowUtilities(!showUtilities)}>
                    <IconMenu />
                </MenuMobile>
            </Nav>
            <OpenMenu 
                showHeader={showUtilities}
                handleShowHeader={() => setShowUtilities(!showUtilities)}
            >
                <>
                    <h3>Navegação</h3>
                    <ButtonMobile>
                        <LinkOne to="/">Início</LinkOne>
                    </ButtonMobile>
                    <ButtonMobile>
                        <LinkOne to="/sobre">Quem somos?</LinkOne>
                    </ButtonMobile>
                    <ButtonMobile>
                        <LinkOne to="/#serviços">Serviços</LinkOne>
                    </ButtonMobile>
                    <ButtonMobile>
                        <A onClick={openModal}>
                            Ouvidoria
                        </A>
                    </ButtonMobile>
                    <ButtonMobile>
                        <A
                            href="http://www.syscambio.com.br:81/WebSac/auth/index/?token=QOXzqFLGQPmid82FTqVPwg%3D%3D&type=3"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Denúncia
                        </A>
                    </ButtonMobile>
                    <ButtonMobile>
                        <LinkOne to="/formulário">Contato</LinkOne>
                    </ButtonMobile>
                    {process.env.SHOW_LOGIN ? <ButtonMobile>
                        <A
                            href={process.env.REACT_APP_DIRECT}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Login
                        </A>
                    </ButtonMobile> : <></>}
                </>
            </OpenMenu>
        </StyledHeader>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{display: 'flex', justifyContent:'space-between'}}>
        <div><b style={{ color:'#aa6443'}}>OUVIDORIA</b></div>
            <div><Button onClick={closeModal}>Fechar</Button></div>
            
        </div>
        <div>
        <br/>Esta instituição integra o <strong style={{ color:'#aa6443'}}>SISTEMA DE COMPARTILHAMENTO DE OUVIDORIA da Associação Brasileira de Câmbio - ABRACAM.</strong>
        <br/><br/>
        <strong style={{ color:'#aa6443'}}>QUANDO UTILIZAR O CANAL DE OUVIDORIA?</strong><br/><br/>
        O serviço de <strong style={{ color:'#aa6443'}}>OUVIDORIA</strong> deve ser <strong style={{ color:'#aa6443'}}>acionado apenas quando os canais regulares de atendimento da DASCAM CORRETORA DE CÂMBIO LTDA pelo telefone que consta neste Site</strong> não atenderem, ou atenderem insatisfatoriamente suas necessidades, por qualquer motivo.<br/><br/>
        Os contatos com a Ouvidoria são formalizados internamente, e possuem prazos para atendimento e solução. Portanto, para que a sua demanda possa ser registrada, analisada e respondida, <strong style={{ color:'#aa6443'}}>é obrigatório informar com clareza no mínimo os seguintes dados</strong>:<br/><br/>
        1) Nome(s) da(s) pessoa(s) com quem se comunicou antes<br/><br/>
        2) Sua identificação e contato:<br/>
        &nbsp;&nbsp;&nbsp;-Nome completo<br/>
        &nbsp;&nbsp;&nbsp;-CPF/CNPJ ou Passaporte<br/>
        &nbsp;&nbsp;&nbsp;-E-mail, ou Endereço de Correspondência (com CEP), para que você possa receber o retorno da OUVIDORIA.<br/><br/>
       3) O motivo de seu contato.<br/><br/>
       <strong style={{ color:'#aa6443'}}>Como entrar em contato com a Ouvidoria?</strong><br/><br/>
       Ligar para <strong style={{ color: 'red' }}>0800 770-5422</strong> (somente chamadas realizadas no Brasil)<br/>
       Este serviço funciona de forma automática, fale com clareza as informações solicitadas.<br/>
       Disponível das 9:00 às 16:30 horas, de segunda a sexta-feira, exceto feriados.<br/><br/>
       Ou através deste <strong><a style={{ color: 'red' }} href="https://risc.advicetech.com.br/Ouvidoria_front/paginas/default.aspx?CD_CHAVE=6485A558-0E33-4E12-923C-DFC8FC713D60" target="_blank" rel="noreferrer">FORMULÁRIO</a></strong>.

        </div>
      </Modal>
    </>
        
    )
}

export default Header;