import Header from '../components/Header';
import MainSection from '../components/MainSection';
import Footer from '../components/Footer';
import OuvidoriaCompletoSection from '../components/OuvidoriaCompletoSection';

const Ouvidoria = (): JSX.Element => (
    <>
        <Header />
        <MainSection fadeDown={true}height={120}/>
        <OuvidoriaCompletoSection />
        <Footer />
    </>
);

export default Ouvidoria;